import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Services We Provide"
      keywords={[`mulch`, `software`, `consulting`]}
    />
    <h1>Software Development Services</h1>

    <section>
      <h2>
        <Link to="/emergency-recovery-services/">
          Emergency and Recovery Services
        </Link>
      </h2>
      <p>
        If your software systems are down and you need them back up quickly, we
        will investigate and fix providing you with real time updates.
      </p>
    </section>
    <section>
      <h2>
        <Link to="/proto-pack/">Proto Pack</Link>
      </h2>
      <p>
        We help you scope out a proof of concept for one of your ideas. Then we
        build this proof of concept over a 4 to 12 week period. Watch or
        participate in our discussions as we develop your product in real time
        and see biweekly updates to a live environment. If you are happy with
        the result, we transition to a new contract to work towards your full
        vision.
      </p>
      <p>
        Our brief and effective research process will help you validate your
        ideas before investment. We learn the ins and outs of your business so
        that we can automate as much as possible and enable your employees to do
        what automation can't.
      </p>
    </section>

    <ul>
      <li>Mobile Apps for iOS and Android</li>
      <li>Fullstack Web Development</li>
      <li>Automate Tasks with OCR and Natural Language Processing</li>
      <li>GDPR Compliance</li>
      <li>Lightning fast search for all your data</li>
      <li>Private and Public Blockchain dApps</li>
      <li>Smart Contracts with Ethereum and Hyperledger</li>
      <li>Salesforce integration</li>
      <li>Microsoft 365 and other Microsoft Enterprise integrations</li>
      <li>SAP Integration</li>
      <li>Oracle integration</li>
      <li>PeopleSoft integration</li>
      <li>Native mobile apps with push notifications</li>
      <li>Realtime location tracking and visualization</li>
      <li>Recruit and hire permanent software engineers</li>
      <li>Analytics and Business Intelligence Dashboards</li>
      <li>Custom ERP</li>
      <li>Email drip campaigns</li>
      <li>NPS monitoring</li>
      <li>Customer Feedback</li>
      <li>Recommendation systems</li>
      <li>Ecommerce platforms and marketplaces</li>
      <li>Search Engine Optimization</li>
      <li>A/B Testing</li>
      <li>IoT Security and Analytics</li>
      <li>Cisco Phone integration</li>
      <li>DRM and Copyright Enforcement</li>
      <li>Live video streaming</li>
      <li>Video encoding and hosting</li>
      <li>Threat modeling</li>
      <li>Kubernetes Cluster Management and Adoption</li>
      <li>Cloud Migrations (AWS, Google Cloud, Azure)</li>
      <li>Server Orchestration with Saltstack</li>
      <li>Configuration management with Saltstack</li>
      <li>Configuration management with Ansible and Terraform</li>
      <li>Containerize / Dockerize services</li>
      <li>Automated physical mail delivery</li>
      <li>Integration with Quickbooks</li>
      <li>Invoicing systems</li>
      <li>Full stack web development</li>
      <li>ETL pipelines</li>
      <li>Map / Reduce with Spark</li>
      <li>Automated content filtering</li>
      <li>Data wrangling and regularization</li>
      <li>EDP and data lakes</li>
    </ul>
    <h1>Technologies We Know</h1>
    <ul>
      <li>Python</li>
      <li>Go</li>
      <li>Ruby</li>
      <li>PostgreSQL</li>
      <li>MySQL</li>
      <li>Node</li>
      <li>React</li>
      <li>Javascript</li>
      <li>Typescript</li>
      <li>Elasticsearch</li>
      <li>Docker</li>
      <li>Kubernetes</li>
      <li>Gitlab</li>
      <li>Github</li>
    </ul>
  </Layout>
)

export default ServicesPage
